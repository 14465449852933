import { useRuntimeConfig } from '#app'
import { combineUrlWithParams } from '~/utils/httpUtils'

// 對話標籤控制器
const { useCustomFetch } = useFetchBase()

const base = '/api/message/'
const module = 'customerTags'
const path = base + module

// request(REQ) Api  --------------------------------
const getCustomerTags = path + '/q' // 獲取客戶標籤列表
const addCustomerTag = path // 新增客戶標籤
const customerTag = path + '/{0}' // 更新客戶標籤 、刪除客戶標籤

/**
 * 獲取所有客戶標籤列表
 * @param {*} params
 * @param {*} callback
 * @returns
 */
const getAllCustomerTagListFn = (params, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(path, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}

/**
 * 獲取客戶標籤列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 分頁數據
 */
const getCustomerTagsFn = (params, callback) => { // 獲取對話標籤列表
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getCustomerTags, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}
/**
 * 新增客戶標籤
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
const addCustomerTagFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addCustomerTag, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 更新客戶標籤
 * @param {String} id 對話標籤id
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
const editCustomerTagFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(customerTag, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除客戶標籤
 * @param {String} id 對話標籤id
 * @param {Object} callback 回掉函數
 * @returns
 */
const deleteCustomerTagFn = (id, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(customerTag, id)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

export {
  getAllCustomerTagListFn,
  getCustomerTagsFn,
  addCustomerTagFn,
  editCustomerTagFn,
  deleteCustomerTagFn
}
